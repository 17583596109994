import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "dashboard-common";
import { Member } from "identity-api";
import { SquarePen } from "lucide-react";
import * as React from "react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "ui-components";
import { DialogFooter } from "ui-components/src";
import * as z from "zod";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";

type EditMemberModalProps = {
  member: Member;
  organizationId: string;
  onMemberUpdated: (member: Member) => void;
};

const editMember = (
  memberId: string,
  role: "admin" | "member",
  organizationId: string,
  getAccessToken: () => Promise<string>,
  onUnauthorized: () => void,
) =>
  createIdentityServiceClient(getAccessToken, onUnauthorized)
    .updateMember({
      parameters: {
        organizationId,
        memberId,
      },
      body: {
        role,
      },
    })
    .then((response) => {
      if (response.code === 200) {
        return response.body;
      } else {
        console.error("Failed to edit member");
        throw new Error(response.body.message);
      }
    });

export const formSchema = z.object({
  role: z.string().min(1, { message: "Role is required" }),
});

function EditMemberModal({
  onMemberUpdated,
  organizationId,
  member,
}: EditMemberModalProps) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button size="icon" className="rounded-lg bg-foreground">
            <SquarePen />
          </Button>
        </DialogTrigger>
        <DialogContent className={error ? "border-destructive" : ""}>
          <DialogHeader>
            <DialogTitle className={error ? "text-destructive" : ""}>
              Edit Member
            </DialogTitle>
          </DialogHeader>
          {open && (
            <EditMember
              member={member}
              organizationId={organizationId}
              onMemberUpdated={onMemberUpdated}
              setOpen={setOpen}
              error={error}
              setError={setError}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

function EditMember({
  member,
  organizationId,
  onMemberUpdated,
  setOpen,
  error,
  setError,
}: EditMemberModalProps & {
  error: Error | null;
  setError: (error: Error | null) => void;
  setOpen: (open: boolean) => void;
}) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    disabled: isSubmitting,
    resolver: zodResolver(formSchema),
    defaultValues: {
      role: member.role,
    },
    mode: "onChange",
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    if (error) {
      setError(null);
    }
    setIsSubmitting(true);

    try {
      const updatedMember = await editMember(
        member.id,
        data.role as "admin" | "member",
        organizationId,
        getAccessToken,
        onUnauthorized,
      );

      onMemberUpdated(updatedMember);

      setOpen(false);
    } catch (e) {
      setError(e as Error);
      return;
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Form {...form}>
      <form>
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem className="my-4">
              <Label className="block mb-2" htmlFor="role">
                Role
              </Label>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a role" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="member">Member</SelectItem>
                  <SelectItem value="admin">Admin</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="mt-4">
          {error && (
            <p className="flex-1 text-destructive self-center">
              <div>There was a problem creating the invite:</div>
              <div>{error.message}</div>
            </p>
          )}
          <Button
            disabled={
              !form.formState.isValid || !form.formState.isDirty || isSubmitting
            }
            variant={error ? "destructive" : "default"}
            onClick={form.handleSubmit(onSubmit)}
          >
            {isSubmitting ? "Updating..." : error ? "Retry" : "Update"}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}

export default EditMemberModal;
