import { Moon, Sun } from "lucide-react";
import * as React from "react";
import { Button } from "ui-components/src";

import { useTheme } from "../../state/MutatableThemeContext";
import { THEME } from "../../types";

export default function ThemeToggle() {
  const { theme, setTheme } = useTheme();

  const toggleTheme = () => {
    if (theme === THEME.dark) {
      setTheme(THEME.light);
      return;
    }

    setTheme(THEME.dark);
  };

  return (
    <div>
      <Button variant="outline" onClick={toggleTheme} size="icon">
        {THEME.dark === theme ? <Sun /> : <Moon />}
      </Button>
    </div>
  );
}
