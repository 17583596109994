import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "dashboard-common";
import { Organization } from "identity-api";
import { SquarePen } from "lucide-react";
import * as React from "react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "ui-components";
import { DialogFooter } from "ui-components/src";
import * as z from "zod";

import { createIdentityServiceClient } from "../../IdentityServiceClient";

const editOrganizationModal = (
  organizationName: string,
  organizationId: string,
  getAccessToken: () => Promise<string>,
  onUnauthorized: () => void,
) =>
  createIdentityServiceClient(getAccessToken, onUnauthorized)
    .updateOrganization({
      parameters: {
        organizationId,
      },
      body: {
        name: organizationName,
      },
    })
    .then((response) => {
      if (response.code === 200) {
        return response.body;
      } else {
        console.error("Failed to update organization");
        throw new Error(response.body.message);
      }
    });

type EditOrganizationModalProps = {
  organization: Organization;
  updateOrganization: (organization: Organization) => void;
  disabled: boolean;
};

export const formSchema = z.object({
  organizationName: z.string().min(1, { message: "Name is required" }),
});

function EditOrganizationModal({
  organization,
  updateOrganization,
  disabled,
}: EditOrganizationModalProps) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  async function onSubmit(data: z.infer<typeof formSchema>) {
    if (error) {
      setError(null);
    }
    setIsSubmitting(true);

    try {
      const res = await editOrganizationModal(
        data.organizationName,
        organization.id,
        getAccessToken,
        onUnauthorized,
      );

      updateOrganization(res);
      setOpen(false);
    } catch (e) {
      setError(e as Error);
      return;
    } finally {
      setIsSubmitting(false);
    }
  }

  const Trigger = disabled ? TooltipTrigger : DialogTrigger;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Tooltip>
        <Trigger asChild>
          <Button
            variant="outline"
            className="absolute top-5 right-5 !pointer-events-auto"
            disabled={disabled}
          >
            <SquarePen />
          </Button>
        </Trigger>
        {disabled && (
          <TooltipContent>
            <p>This action can only be performed by an Admin</p>
          </TooltipContent>
        )}
      </Tooltip>
      <DialogContent className={error ? "border-destructive" : ""}>
        <DialogHeader>
          <DialogTitle className={error ? "text-destructive" : ""}>
            Edit {organization.name}
          </DialogTitle>
        </DialogHeader>
        {open && (
          <EditOrganizationForm
            organization={organization}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            error={error}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

function EditOrganizationForm({
  organization,
  onSubmit,
  isSubmitting,
  error,
}: {
  organization: Organization;
  onSubmit: (data: z.infer<typeof formSchema>) => void;
  isSubmitting: boolean;
  error: Error | null;
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    disabled: isSubmitting,
    resolver: zodResolver(formSchema),
    defaultValues: {
      organizationName: organization.name,
    },
    mode: "onChange",
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Label className="inline-block mb-2">Id</Label>
        <Input type="text" value={organization.id} disabled />
        <FormField
          control={form.control}
          name="organizationName"
          render={({ field }) => (
            <FormItem className="w-full mt-2">
              <Label className="inline-block mb-2">Name</Label>
              <Input
                id="organization-name"
                type="text"
                className="flex-1"
                {...field}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="mt-4">
          {error && (
            <p className="flex-1 text-destructive self-center">
              <div>There was a problem editing the organization:</div>
              <div>{error.message}</div>
            </p>
          )}
          <Button
            disabled={!form.formState.isValid || isSubmitting}
            variant={error ? "destructive" : "default"}
          >
            {isSubmitting ? "Updating..." : error ? "Retry" : "Update"}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}

export default EditOrganizationModal;
