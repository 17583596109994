import * as React from "react";
import { useLayoutEffect, useState } from "react";
import { Button } from "ui-components";

export default function CookieBanner() {
  const [hasHandledCookies, setHasHandledCookies] = useState(false);

  const rejectCookies = () => {
    document.cookie = "cookiesAccepted=false;path=/;samesite=strict";
    setHasHandledCookies(true);
  };

  const acceptCookies = () => {
    document.cookie = "cookiesAccepted=true;path=/;samesite=strict";
    setHasHandledCookies(true);
  };

  useLayoutEffect(() => {
    const cookies = document.cookie.split("; ");
    const cookieValue = cookies.find((row) =>
      row.startsWith("cookiesAccepted="),
    );
    if (cookieValue) {
      setHasHandledCookies(true);
    }
  }, []);

  if (hasHandledCookies) {
    return null;
  }

  return (
    <div className="fixed h-40 bottom-0 left-0 right-0 bg-background/70 px-8 py-4 flex gap-4 border border-border rounded">
      <p>
        We use essential cookies to make our site work. With your consent, we
        may also use non-essential cookies to improve user experience and
        analyse website traffic. By clicking ‘Accept’, you agree to our
        website’s cookie use as described in our{" "}
        <a
          href="https://www.improbable.io/cookie-policy"
          target="_blank"
          rel="noreferrer"
        >
          Cookie Policy
        </a>
        .
      </p>
      <div className="flex gap-3">
        <Button onClick={acceptCookies}>Accept</Button>
        <Button variant="secondary" onClick={rejectCookies}>
          Reject
        </Button>
      </div>
    </div>
  );
}
