import { Member, OrganizationRole, OrganizationRoleAdmin } from "identity-api";
import { Trash } from "lucide-react";
import * as React from "react";
import { Button, H2, Sonner, useDocumentTitle } from "ui-components";
import { useLocation } from "wouter";

import DeleteMemberModal from "../MembersListView/DeleteMemberModal";

type MemberInfoProps = {
  member: Member | null;
  organizationId: string;
  organizationDisplayName: string;
  organizationRole: OrganizationRole;
};

export function MemberInfo({
  member,
  organizationId,
  organizationDisplayName,
  organizationRole,
}: MemberInfoProps) {
  const [, setLocation] = useLocation();
  useDocumentTitle(
    `Member - ${member?.userInfo.displayName || "Loading"} - ${organizationDisplayName}`,
  );
  // TODO - use skeleton when loading
  return (
    <>
      <div className="mb-20 h-10 flex items-center">
        <H2>Member: {member?.userInfo.displayName}</H2>
      </div>

      <dl className="flex items-end m-0 *:m-0 *:p-0 gap-2">
        <dt>Member User Id:</dt>
        <dd>{member?.userId}</dd>
      </dl>

      <dl className="flex items-end m-0 *:m-0 *:p-0 gap-2">
        <dt>Member Email:</dt>
        <dd>{member?.userInfo.email}</dd>
      </dl>

      <dl className="flex items-end m-0 *:m-0 *:p-0 gap-2">
        <dt>Member Role:</dt>
        <dd>{member?.role}</dd>
      </dl>

      {member && organizationRole === OrganizationRoleAdmin && (
        <DeleteMemberModal
          organizationId={organizationId}
          memberId={member.id}
          onMemberDeleted={() => {
            Sonner.toast("Member deleted");
            setLocation(`/organizations/${organizationId}/members`);
          }}
        >
          <Button
            variant="destructive"
            className="flex justify-between gap-2 mt-4"
          >
            Delete Member <Trash />
          </Button>
        </DeleteMemberModal>
      )}
    </>
  );
}
