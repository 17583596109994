import { AuthContext } from "dashboard-common";
import { OrganizationInviteOffer } from "identity-api";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Content, Sonner, useDataFetcherState } from "ui-components";
import { H1, H2 } from "ui-components/src/components/ui";
import { useLocation, useParams, useSearch } from "wouter";

import { createIdentityServiceClient } from "../../IdentityServiceClient";

export default function AcceptInvite() {
  const { organizationId, inviteId } =
    useParams<"/accept-invite/:organizationId/:inviteId">();
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [, setLocation] = useLocation();
  const query = new URLSearchParams(useSearch());
  const token = query.get("token");

  const [isAccepting, setIsAccepting] = useState(false);
  const [acceptingError, setAcceptingError] = useState<Error | null>(null);

  const dataFetcher = useDataFetcherState<OrganizationInviteOffer>();
  useEffect(() => {
    if (!token) {
      dataFetcher.setError({ message: "No token provided" });
      return;
    }
    if (!organizationId) {
      dataFetcher.setError({ message: "No organizationId provided" });
      return;
    }
    dataFetcher.setIsLoading(true);

    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .getInviteOffer({
        parameters: {
          inviteId,
          organizationId,
          token,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          console.error("Failed to fetch organization");
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [
    getAccessToken,
    onUnauthorized,
    inviteId,
    organizationId,
    dataFetcher,
    token,
  ]);

  const handleAccept = useCallback(() => {
    if (!organizationId || !token || !dataFetcher.data) {
      return;
    }
    setAcceptingError(null);
    setIsAccepting(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .acceptInviteOffer({
        parameters: {
          organizationId,
          inviteId,
        },
        body: {
          token,
        },
      })
      .then((response) => {
        if (response.code === 200) {
          Sonner.toast("Invite accepted");
          setLocation(`/organizations/${dataFetcher.data.organizationId}/`);
        } else {
          console.error("Failed to accept invite");
          throw new Error(response.body.message);
        }
      })
      .catch((err) => {
        console.error(err);
        setAcceptingError(err);
      })
      .finally(() => setIsAccepting(false));
  }, [
    getAccessToken,
    inviteId,
    organizationId,
    onUnauthorized,
    setLocation,
    token,
    dataFetcher.data,
  ]);

  const inviteOffer = dataFetcher.data;

  return (
    <Content>
      {dataFetcher.isLoading && (
        <div className="mb-20">
          <H1>Loading...</H1>
        </div>
      )}
      {dataFetcher.error && (
        <div className="mb-12 p-6 max-w-none rounded-lg relative border border-border">
          <H2 className="font-bold m-0">Error</H2>
          <p>{dataFetcher.error.message}</p>
        </div>
      )}
      {inviteOffer && (
        <div className="mb-20">
          <H1>Invite: {inviteOffer?.email}</H1>
          <p>
            You have been invited to join the organization{" "}
            <strong>{inviteOffer.organizationName}</strong> with the role{" "}
            <strong>{inviteOffer.role}</strong>.
          </p>
          {acceptingError && (
            <p className="flex-1 text-destructive self-center">
              <div>There was a problem accepting the invite:</div>
              <div>{acceptingError.message}</div>
            </p>
          )}
          <Button
            className="mt-4"
            onClick={handleAccept}
            disabled={isAccepting}
          >
            {isAccepting ? "Accepting..." : "Accept"}
          </Button>
        </div>
      )}
    </Content>
  );
}
