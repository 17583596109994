import { AuthProvider } from "dashboard-common";
import * as React from "react";
import { useCallback } from "react";
import { NotFound, Sonner } from "ui-components";
import { Route, Switch, useLocation } from "wouter";

import AcceptInvite from "./components/AcceptInvite/AcceptInvite";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import { Home } from "./components/Home/Home";
import OrganizationDashboard from "./components/OrganizationDashboard/OrganizationDashboard";
import ProfileView from "./components/ProfileView/ProfileView";
import ProjectDashboard from "./components/ProjectDashboard/ProjectDashboard";
import { TopNav } from "./components/TopNav/TopNav";
import { MutatableThemeProvider } from "./state/MutatableThemeContext";

export type DashboardProps = {
  user: {
    id: string;
    email?: string;
    name?: string | null;
    image?: string | null;
  };
  sessionToken: string;
  onUnauthorized: () => void;
};

function DashboardInner({
  user,
  sessionToken,
  onUnauthorized,
}: DashboardProps) {
  const getAccessToken = useCallback(() => {
    return Promise.resolve(sessionToken);
  }, [sessionToken]);

  const dashboardUser = {
    displayName: user.name!,
    email: user.email!,
    photoURL: user.image!,
    uid: user.id!,
  };

  let projectId: string | null = null;
  const [location] = useLocation();
  const splitLocation = location.split("/");
  if (splitLocation[1] === "projects" && splitLocation.length > 2) {
    projectId = splitLocation[2];
  }

  return (
    <MutatableThemeProvider defaultTheme="dark" storageKey="ui-theme">
      <AuthProvider
        user={dashboardUser}
        getAccessToken={getAccessToken}
        onUnauthorized={onUnauthorized}
      >
        <Sonner />
        <Switch>
          <Route path={`/projects?/:projectId?/*`}>
            <TopNav projectId={projectId!} />
          </Route>
          <Route path={`/*`}>
            <TopNav />
          </Route>
        </Switch>
        <div className="flex w-screen min-h-[calc(100vh-64px)] overflow-x-hidden">
          <Switch>
            <Route path={`/profile`}>
              <ProfileView />
            </Route>
            <Route path={`/projects/:projectId/*`}>
              <ProjectDashboard
                user={user}
                sessionToken={sessionToken}
                onUnauthorized={onUnauthorized}
              />
            </Route>
            <Route path={`/`}>
              <Home />
            </Route>
            <Route path={`/organizations`}>
              <Home />
            </Route>
            <Route path={`/organizations/:organizationId/*?`}>
              <OrganizationDashboard />
            </Route>
            <Route path={`/accept-invite/:organizationId/:inviteId`}>
              <AcceptInvite />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </div>
        <CookieBanner />
      </AuthProvider>
    </MutatableThemeProvider>
  );
}

function isEqual(prevProps: DashboardProps, nextProps: DashboardProps) {
  return deepEqual(prevProps, nextProps);
}

export const Dashboard = React.memo(DashboardInner, isEqual);

function deepEqual(a: any, b: any): boolean {
  if (a === b) {
    return true;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false;
    }

    return a.every((elem, index) => {
      return deepEqual(elem, b[index]);
    });
  }

  if (
    typeof a === "object" &&
    typeof b === "object" &&
    a !== null &&
    b !== null
  ) {
    if (Array.isArray(a) || Array.isArray(b)) {
      return false;
    }

    const keys1 = Object.keys(a);
    const keys2 = Object.keys(b);
    if (
      keys1.length !== keys2.length ||
      !keys1.every((key) => keys2.includes(key))
    ) {
      return false;
    }

    for (const key in a) {
      if (!deepEqual(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }
  return false;
}
