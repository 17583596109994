import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "dashboard-common";
import { Team } from "identity-api";
import { SquarePen } from "lucide-react";
import * as React from "react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "ui-components";
import { DialogFooter } from "ui-components/src";
import * as z from "zod";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";

const editTeamModal = (
  team: string,
  teamId: string,
  organizationId: string,
  getAccessToken: () => Promise<string>,
  onUnauthorized: () => void,
) =>
  createIdentityServiceClient(getAccessToken, onUnauthorized)
    .updateTeam({
      parameters: {
        teamId,
        organizationId,
      },
      body: {
        name: team,
      },
    })
    .then((response) => {
      if (response.code === 200) {
        return response.body;
      } else {
        console.error("Failed to update team");
        throw new Error(response.body.message);
      }
    });

type EditTeamModalProps = {
  team: Team;
  organizationId: string;
  updateTeam: (team: Team) => void;
  disabled: boolean;
};

export const formSchema = z.object({
  team: z.string().min(1, { message: "Name is required" }),
});

function EditTeamModal({
  team,
  organizationId,
  updateTeam,
  disabled,
}: EditTeamModalProps) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState<Error | null>(null);

  const Trigger = disabled ? TooltipTrigger : DialogTrigger;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Tooltip>
        <Trigger asChild>
          <Button
            variant="outline"
            className="absolute top-5 right-5 !pointer-events-auto"
            disabled={disabled}
          >
            <SquarePen />
          </Button>
        </Trigger>
        {disabled && (
          <TooltipContent>
            <p>This action can only be performed by an Admin</p>
          </TooltipContent>
        )}
      </Tooltip>
      <DialogContent className={error ? "border-destructive" : ""}>
        <DialogHeader>
          <DialogTitle className={error ? "text-destructive" : ""}>
            Edit {team.name}
          </DialogTitle>
          {open && (
            <EditTeamModalForm
              team={team}
              organizationId={organizationId}
              updateTeam={updateTeam}
              error={error}
              setError={setError}
              setOpen={setOpen}
            />
          )}
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

function EditTeamModalForm({
  team,
  organizationId,
  updateTeam,
  error,
  setError,
  setOpen,
}: Omit<EditTeamModalProps, "disabled"> & {
  error: Error | null;
  setError: (error: Error | null) => void;
  setOpen: (open: boolean) => void;
}) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    disabled: isSubmitting,
    resolver: zodResolver(formSchema),
    defaultValues: {
      team: team.name,
    },
    mode: "onChange",
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    if (error) {
      setError(null);
    }
    setIsSubmitting(true);

    try {
      const res = await editTeamModal(
        data.team,
        team.id,
        organizationId,
        getAccessToken,
        onUnauthorized,
      );

      updateTeam(res);
      setOpen(false);
    } catch (e) {
      setError(e as Error);
      return;
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Label className="inline-block mb-2">Id</Label>
        <Input type="text" value={team.id} disabled />
        <FormField
          control={form.control}
          name="team"
          render={({ field }) => (
            <FormItem className="w-full mt-2">
              <Label className="inline-block mb-2">Name</Label>
              <Input id="team-name" type="text" className="flex-1" {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="mt-4">
          {error && (
            <p className="flex-1 text-destructive self-center">
              <div>There was a problem editing the team:</div>
              <div>{error.message}</div>
            </p>
          )}
          <Button
            disabled={!form.formState.isValid || isSubmitting}
            variant={error ? "destructive" : "default"}
          >
            {isSubmitting ? "Updating..." : error ? "Retry" : "Update"}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}

export default EditTeamModal;
