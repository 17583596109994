import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "dashboard-common";
import { APIKey } from "identity-api";
import * as React from "react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "ui-components";
import { DialogFooter } from "ui-components/src";
import * as z from "zod";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";

type CreateAPIKeyModalProps = {
  organizationId: string;
  onAPIKeyCreated: (createdKey: {
    apiKey: APIKey;
    apiKeyToken: string;
  }) => void;
  disabled: boolean;
};

const createAPIKey = (
  apiKeyName: string,
  organizationId: string,
  getAccessToken: () => Promise<string>,
  onUnauthorized: () => void,
) =>
  createIdentityServiceClient(getAccessToken, onUnauthorized)
    .createAPIKey({
      parameters: {
        organizationId,
      },
      body: {
        name: apiKeyName,
      },
    })
    .then((response) => {
      if (response.code === 200) {
        return response.body;
      } else {
        console.error("Failed to create api key");
        throw new Error(response.body.message);
      }
    });

export const formSchema = z.object({
  apiKeyName: z.string().min(1, { message: "Name is required" }),
});

function CreateAPIKeyModal({
  onAPIKeyCreated,
  organizationId,
  disabled,
}: CreateAPIKeyModalProps) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const Trigger = disabled ? TooltipTrigger : DialogTrigger;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Tooltip>
        <Trigger asChild>
          <Button
            className="rounded-lg bg-foreground !pointer-events-auto"
            disabled={disabled}
          >
            Create API Key
          </Button>
        </Trigger>
        {disabled && (
          <TooltipContent>
            <p>This action can only be performed by an Admin</p>
          </TooltipContent>
        )}
      </Tooltip>
      <DialogContent className={error ? "border-destructive" : ""}>
        <DialogHeader>
          <DialogTitle className={error ? "text-destructive" : ""}>
            Create API Key
          </DialogTitle>
        </DialogHeader>
        {open && (
          <CreateAPIKeyModalForm
            organizationId={organizationId}
            onAPIKeyCreated={onAPIKeyCreated}
            setOpen={setOpen}
            error={error}
            setError={setError}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

function CreateAPIKeyModalForm({
  organizationId,
  onAPIKeyCreated,
  setOpen,
  error,
  setError,
}: Omit<CreateAPIKeyModalProps, "disabled"> & {
  error: Error | null;
  setError: (error: Error | null) => void;
  setOpen: (open: boolean) => void;
}) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    disabled: isSubmitting,
    resolver: zodResolver(formSchema),
    defaultValues: {
      apiKeyName: "",
    },
    mode: "onChange",
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    if (error) {
      setError(null);
    }
    setIsSubmitting(true);

    try {
      const apiKeyResponse = await createAPIKey(
        data.apiKeyName,
        organizationId,
        getAccessToken,
        onUnauthorized,
      );

      onAPIKeyCreated(apiKeyResponse);
      setOpen(false);
    } catch (e) {
      setError(e as Error);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="apiKeyName"
          render={({ field }) => (
            <FormItem className="mb-4">
              <Label className="block mb-2" htmlFor="api-key-name">
                API Key Name
              </Label>
              <Input
                id="api-key-name"
                type="text"
                placeholder="API Key Name"
                {...field}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="mt-4">
          {error && (
            <p className="flex-1 text-destructive self-center">
              <div>There was a problem creating the API key:</div>
              <div>{error.message}</div>
            </p>
          )}
          <Button
            disabled={!form.formState.isValid || isSubmitting}
            variant={error ? "destructive" : "default"}
          >
            {isSubmitting ? "Creating..." : error ? "Retry" : "Create"}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}

export default CreateAPIKeyModal;
