import React, { createContext, useContext, useEffect, useState } from "react";

import { THEME } from "../types";

type Theme = typeof THEME.dark | typeof THEME.light | "system";

type MutatableThemeProviderProps = {
  children: React.ReactNode;
  defaultTheme?: Theme;
  storageKey?: string;
};

type MutatableThemeProviderState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const initialState: MutatableThemeProviderState = {
  theme: "system",
  setTheme: () => null,
};

const MutatableThemeProviderContext =
  createContext<MutatableThemeProviderState>(initialState);

export function MutatableThemeProvider({
  children,
  defaultTheme = "system",
  storageKey = "ui-theme",
  ...props
}: MutatableThemeProviderProps) {
  const [theme, setTheme] = useState<Theme>(
    () => (localStorage.getItem(storageKey) as Theme) || defaultTheme,
  );

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove(THEME.light, THEME.dark);

    if (theme === "system") {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
        ? THEME.dark
        : THEME.light;

      root.classList.add(systemTheme);
      return;
    }

    root.classList.add(theme);
  }, [theme]);

  const value = {
    theme,
    setTheme: (theme: Theme) => {
      localStorage.setItem(storageKey, theme);
      setTheme(theme);
    },
  };

  return (
    <MutatableThemeProviderContext.Provider {...props} value={value}>
      {children}
    </MutatableThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(MutatableThemeProviderContext);

  if (context === undefined)
    throw new Error("useTheme must be used within a MutatableThemeProvider");

  return context;
};
