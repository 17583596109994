import { AuthContext } from "dashboard-common";
import {
  ListTeamPermissionsSuccessResponse,
  OrganizationRole,
  OrganizationRoleAdmin,
  Team,
  TeamPermission,
  TeamRoleAdmin,
} from "identity-api";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  BasicList,
  DocsLink,
  EmptyState,
  ErrorAlert,
  H2,
  ItemPagination,
  ListElement,
  ListElementSkeleton,
  Sonner,
  Title,
  useDataFetcherState,
  useDocumentTitle,
} from "ui-components";

import CreateTeamPermissionModal from "./CreateTeamPermissionModal";
import DeleteTeamPermissionModal from "./DeleteTeamPermissionModal";
import { createIdentityServiceClient } from "../../../../IdentityServiceClient";
import { permissionResourceToDisplayName } from "../../permissionResources";

type TeamPermissionsListViewProps = {
  teamId: string;
  organizationId: string;
  organizationDisplayName: string;
  team: Team | null;
  organizationRole: OrganizationRole;
};

export function TeamPermissionsListView({
  teamId,
  organizationId,
  organizationDisplayName,
  team,
  organizationRole,
}: TeamPermissionsListViewProps) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const dataFetcher =
    useDataFetcherState<ListTeamPermissionsSuccessResponse["body"]>();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const fetchTeamPermissions = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listPermissionsForTeam({
        parameters: {
          organizationId,
          teamId,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [
    getAccessToken,
    onUnauthorized,
    teamId,
    currentPage,
    organizationId,
    dataFetcher,
  ]);

  useEffect(() => {
    fetchTeamPermissions();
  }, [fetchTeamPermissions]);

  useDocumentTitle(
    `Team Permissions - ${team?.name || "Loading..."} - ${organizationDisplayName}`,
  );

  const { teamRole = null } = dataFetcher.data || {};

  return (
    <>
      <Title>
        <div className="flex gap-2 items-baseline">
          <H2>{team ? `Team ${team?.name}` : "Loading"}</H2>
          <DocsLink path="/organizations/team/team-permissions" text="Docs" />
        </div>
        <CreateTeamPermissionModal
          teamId={teamId}
          organizationId={organizationId}
          onTeamPermissionCreated={() => {
            fetchTeamPermissions();
            Sonner.toast("Team Permission created");
          }}
          disabled={
            organizationRole !== OrganizationRoleAdmin &&
            teamRole !== TeamRoleAdmin
          }
        />
      </Title>
      <div className="w-full min-h-full mx-auto pb-20 relative">
        <ItemPagination
          loading={dataFetcher.isLoading}
          className="pt-20"
          totalItems={dataFetcher.data?.totalResults || null} // total number of available items
          itemsPerPage={itemsPerPage}
          currentPage={currentPage} // initial page
          setPage={setCurrentPage} // callback to set the current page
        >
          <BasicList>
            {dataFetcher.error && (
              <ErrorAlert
                message={`There was a problem fetching the team permissions: ${dataFetcher.error.message}`}
                action={{
                  text: "Retry",
                  cb: () => {
                    fetchTeamPermissions();
                  },
                }}
              />
            )}
            {dataFetcher.isLoading &&
              Array.from(Array(12).keys()).map((i) => (
                <ListElementSkeleton key={i} />
              ))}
            {dataFetcher.data?.teamPermissions.map(
              (teamPermission: TeamPermission) => (
                <ListElement key={teamPermission.id} noLink>
                  <div className="flex-col flex text-sm basis-1/3">
                    Project ID:
                    <span className="text-xl font-semibold">
                      {teamPermission.projectId}
                    </span>
                  </div>
                  <div className="flex-col flex text-sm basis-1/3">
                    Verb:
                    <span className="text-xl font-semibold">
                      {teamPermission.permission.verb}
                    </span>
                  </div>
                  <div className="flex-col flex text-sm">
                    Resource:
                    <span className="text-xl font-semibold">
                      {permissionResourceToDisplayName(
                        teamPermission.permission.resource,
                      )}
                    </span>
                  </div>
                  <div className="ml-auto flex gap-2 mr-4">
                    {(organizationRole === OrganizationRoleAdmin ||
                      dataFetcher.data?.teamRole === TeamRoleAdmin) && (
                      <DeleteTeamPermissionModal
                        teamId={teamId}
                        organizationId={organizationId}
                        permissionId={teamPermission.id}
                        onTeamPermissionDeleted={() => {
                          fetchTeamPermissions();
                          Sonner.toast("Team Permission deleted");
                        }}
                      />
                    )}
                  </div>
                </ListElement>
              ),
            )}
            {dataFetcher.data && !dataFetcher.data.teamPermissions.length && (
              <EmptyState message="There are no permissions." />
            )}
          </BasicList>
        </ItemPagination>
      </div>
    </>
  );
}
