import { Building2, Home } from "lucide-react";
import { Fragment } from "react";
import * as React from "react";
import { cn, H4 } from "ui-components";
import { Link, useLocation } from "wouter";

import { links } from "./organizationData";

export function OrganizationsNav(props: { orgId: string; orgName: string }) {
  const [location] = useLocation();
  const linkLocation = location.split("/")[3];

  return (
    <nav className="flex p-3 gap-2 flex-col overflow-auto w-64 border-r border-r-border px-3 py-6">
      <Link to="/">
        <div className="items-center py-2 px-4 mt-1 leading-5 font-semibold text-sm flex gap-2">
          <Home />
          Home
        </div>
      </Link>
      <Link to={`/profile/`}>
        <div
          className={cn(
            "items-center py-2 px-4 mt-1 leading-5 font-semibold text-sm flex gap-2",
          )}
        >
          <Building2 />
          Organizations
        </div>
      </Link>
      <H4 className="py-2 px-4">{props.orgName}</H4>
      {links.map((item) => {
        const Icon = item.icon;
        return (
          <Fragment key={item.name}>
            <Link to={`/organizations/${props.orgId}${item.path}`}>
              <div
                className={cn(
                  "items-center py-2 px-4 mt-1 leading-5 font-semibold text-sm flex gap-2",
                  (`/${linkLocation}` === item.path ||
                    (linkLocation === undefined && item.path === "")) &&
                    "bg-secondary rounded",
                )}
              >
                <Icon />
                {item.name}
              </div>
            </Link>
          </Fragment>
        );
      })}
    </nav>
  );
}
