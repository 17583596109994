import { dashboardServiceSchema } from "dashboard-api";
import {
  createOpenAPIClient,
  OpenAPIAuthMiddleware,
} from "openapi-typescript-helpers";

const dashboardServiceAPIUrl =
  window.serverConfig.API_URL || "http://localhost:3030";

export function createDashboardServiceClient(
  getAccessToken: () => Promise<string>,
  onUnauthorized?: () => void,
) {
  return createOpenAPIClient(
    dashboardServiceSchema,
    {
      baseUrl: dashboardServiceAPIUrl,
    },
    [OpenAPIAuthMiddleware(getAccessToken, onUnauthorized)],
  );
}
