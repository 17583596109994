import { DashboardApp } from "dashboard-common";
import { Boxes, Globe2, LucideIcon } from "lucide-react";
import * as MMLObjectsUI from "mml-objects-ui";
import * as WorldsUI from "worlds-ui";

export type Item = DashboardApp & {
  icon: LucideIcon;
};

export type Section = {
  name: string;
  items: Array<Item>;
};

const appsSection: Section = {
  name: "Products",
  items: [
    {
      ...WorldsUI.WorldsUI,
      icon: Globe2,
    },
    {
      ...MMLObjectsUI.MMLObjectsUI,
      icon: Boxes,
    },
  ],
} as const;

export type Apps = Array<Section>;
export const apps: Apps = [appsSection];
