import { AuthContext } from "dashboard-common";
import { ListOrganizationsSuccessResponse } from "identity-api";
import { ChevronDown } from "lucide-react";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  buttonVariants,
  cn,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  useDataFetcherState,
} from "ui-components";

import { createIdentityServiceClient } from "../../IdentityServiceClient";

type SelectOrganizationInputProps = {
  value: string;
  onChange: (value: string) => void;
};

function SelectOrganizationInput({
  value,
  onChange,
}: SelectOrganizationInputProps) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const dataFetcher =
    useDataFetcherState<ListOrganizationsSuccessResponse["body"]>();

  const currentOrganization = dataFetcher.data?.organizations.find(
    (organization) => organization.id === value,
  );

  useEffect(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listOrganizations({
        parameters: {},
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [getAccessToken, dataFetcher, onUnauthorized]);

  const selectedOrganizationName = currentOrganization?.name;

  const handleOrganizationClick = (id: string) => {
    const organizationToSet = dataFetcher.data?.organizations.find(
      (p) => id === p.id,
    );
    if (!organizationToSet) {
      return;
    }

    onChange(organizationToSet.id);
    setOpen(false);
  };

  if (dataFetcher.isLoading) {
    return (
      <div
        className={cn(
          buttonVariants(),
          "bg-foreground min-h-0 h-10 w-full min-w-40 rounded-lg px-10 opacity-30",
        )}
      >
        Loading...
      </div>
    );
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="bg-foreground min-h-0 h-10 w-full min-w-40 rounded-lg px-10">
          {selectedOrganizationName ?? "Select Organization"}
          <ChevronDown />
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select Organization</DialogTitle>
        </DialogHeader>
        <div>
          <ul className="flex flex-col gap-2">
            {dataFetcher.data?.organizations.map((organization) => (
              <li key={organization.id}>
                <Button
                  key={organization.id}
                  className="w-full"
                  onClick={() => handleOrganizationClick(organization.id)}
                >
                  {organization.name}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SelectOrganizationInput;
