import { identityServiceSchema } from "identity-api";
import {
  createOpenAPIClient,
  OpenAPIAuthMiddleware,
} from "openapi-typescript-helpers";

const identityServiceAPIUrl =
  window.serverConfig.API_URL || "http://localhost:3050";

export function createIdentityServiceClient(
  getAccessToken: () => Promise<string>,
  onUnauthorized?: () => void,
) {
  return createOpenAPIClient(
    identityServiceSchema,
    {
      baseUrl: identityServiceAPIUrl,
    },
    [OpenAPIAuthMiddleware(getAccessToken, onUnauthorized)],
  );
}
