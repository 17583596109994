import * as React from "react";
import { Link } from "wouter";

export function LogoHeader() {
  return (
    <div className="flex justify-center">
      <div className="ml-2 flex-0">
        <Link to="/">
          <span className="font-bold">MServe</span>
        </Link>
      </div>
    </div>
  );
}
