import { OrganizationRole, OrganizationRoleAdmin, Team } from "identity-api";
import * as React from "react";
import {
  DocsLink,
  H2,
  Skeleton,
  Sonner,
  useDocumentTitle,
} from "ui-components";

import EditTeamModal from "./EditTeamModal";

type TeamInfoProps = {
  teamId: string;
  organizationId: string;
  organizationDisplayName: string;
  team: Team | null;
  loading: boolean;
  onTeamUpdate: (team: Team) => void;
  organizationRole: OrganizationRole;
};

export function TeamInfo({
  team,
  onTeamUpdate,
  organizationId,
  organizationDisplayName,
  loading,
  organizationRole,
}: TeamInfoProps) {
  useDocumentTitle(
    `Team - ${loading ? "Loading..." : team?.name || "Error"} - ${organizationDisplayName}`,
  );
  if (loading) {
    return (
      <Skeleton className="mb-12 max-w-none rounded-lg relative">
        <H2 className="font-bold mb-8">Team Info</H2>
        <Skeleton className="flex items-end m-0 *:m-0 *:p-0 gap-2 h-7"></Skeleton>
        <Skeleton className="flex items-end m-0 *:m-0 *:p-0 gap-2 h-7"></Skeleton>
        <Skeleton className="flex items-end m-0 *:m-0 *:p-0 gap-2 h-7"></Skeleton>
      </Skeleton>
    );
  }
  return (
    <>
      <div className="mb-12 max-w-none rounded-lg relative">
        <div className="flex justify-between items-center mb-4 h-10">
          <div className="flex gap-2 items-baseline">
            <H2>{team ? `Team ${team?.name}` : "Loading"}</H2>
            <DocsLink path="/organizations/team" text="Docs" />
          </div>
        </div>
        {team && (
          <EditTeamModal
            team={team}
            organizationId={organizationId}
            updateTeam={(updatedTeam) => {
              onTeamUpdate(updatedTeam);
              Sonner.toast("Team updated");
            }}
            disabled={organizationRole !== OrganizationRoleAdmin}
          />
        )}
        <dl className="flex items-end m-0 *:m-0 *:p-0 gap-2">
          <dt>Team Id:</dt>
          <dd>{team?.id}</dd>
        </dl>
      </div>
    </>
  );
}
