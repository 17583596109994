import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "dashboard-common";
import { Team } from "identity-api";
import * as React from "react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "ui-components";
import { DialogFooter } from "ui-components/src";
import * as z from "zod";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";

type CreateTeamModalProps = {
  organizationId: string;
  onTeamCreated: (team: Team) => void;
  disabled: boolean;
};

const createTeam = (
  teamName: string,
  organizationId: string,
  getAccessToken: () => Promise<string>,
  onUnauthorized: () => void,
) =>
  createIdentityServiceClient(getAccessToken, onUnauthorized)
    .createTeam({
      parameters: {
        organizationId,
      },
      body: {
        name: teamName,
      },
    })
    .then((response) => {
      if (response.code === 200) {
        return response.body;
      } else {
        console.error("Failed to create team");
        throw new Error(response.body.message);
      }
    });

export const formSchema = z.object({
  teamName: z.string().min(1, { message: "Name is required" }),
});

function CreateTeamModal({
  onTeamCreated,
  organizationId,
  disabled,
}: CreateTeamModalProps) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    disabled: isSubmitting,
    resolver: zodResolver(formSchema),
    defaultValues: {
      teamName: "",
    },
    mode: "onChange",
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    if (error) {
      setError(null);
    }
    setIsSubmitting(true);

    try {
      const team = await createTeam(
        data.teamName,
        organizationId,
        getAccessToken,
        onUnauthorized,
      );

      onTeamCreated(team);
      setOpen(false);
    } catch (e) {
      setError(e as Error);
      return;
    } finally {
      setIsSubmitting(false);
    }
  }

  const Trigger = disabled ? TooltipTrigger : DialogTrigger;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Tooltip>
        <Trigger asChild>
          <Button
            className="rounded-lg bg-foreground !pointer-events-auto"
            disabled={disabled}
          >
            Create Team
          </Button>
        </Trigger>
        {disabled && (
          <TooltipContent>
            <p>This action can only be performed by an Admin</p>
          </TooltipContent>
        )}
      </Tooltip>
      <DialogContent className={error ? "border-destructive" : ""}>
        <DialogHeader>
          <DialogTitle className={error ? "text-destructive" : ""}>
            Create Team
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="teamName"
              render={({ field }) => (
                <FormItem>
                  <Label className="block mb-2" htmlFor="team-name">
                    Team Name
                  </Label>
                  <Input
                    id="team-name"
                    type="text"
                    placeholder="Team Name"
                    {...field}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="mt-4">
              {error && (
                <p className="flex-1 text-destructive self-center">
                  <div>There was a problem creating the team:</div>
                  <div>{error.message}</div>
                </p>
              )}
              <Button
                disabled={!form.formState.isValid || isSubmitting}
                variant={error ? "destructive" : "default"}
              >
                {isSubmitting ? "Creating..." : error ? "Retry" : "Create"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateTeamModal;
