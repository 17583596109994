import {
  Folder,
  Info,
  KeyRound,
  LucideIcon,
  Mailbox,
  User,
  Users,
} from "lucide-react";

export type OrganizationMenuItemSubsection = {
  name: string;
  path: (id: string) => string;
  showCondition: (path: string) => boolean;
};

export type OrganizationMenuItem = {
  name: string;
  path: string;
  subsections?: Array<OrganizationMenuItemSubsection>;
};

export type Item = OrganizationMenuItem & {
  icon: LucideIcon;
};

export type Section = Array<Item>;

export const links: Section = [
  {
    name: "Info",
    icon: Info,
    path: "",
  },
  {
    name: "Projects",
    icon: Folder,
    path: "/projects",
  },
  {
    name: "Teams",
    icon: Users,
    path: "/teams",
  },
  {
    name: "Members",
    icon: User,
    path: "/members",
  },
  {
    name: "API Keys",
    icon: KeyRound,
    path: "/api-keys",
  },
  {
    name: "Invites",
    icon: Mailbox,
    path: "/invites",
  },
] as const;
