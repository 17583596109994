import { Home, LayoutDashboard } from "lucide-react";
import * as React from "react";
import { Fragment } from "react";
import { cn } from "ui-components";
import { Link, useLocation } from "wouter";

import { apps } from "../../apps";

export function ServicesNav(props: { projectId: string }) {
  const [location] = useLocation();
  const linkLocation = location.split("/")[3];

  return (
    <nav className="flex p-3 gap-2 flex-col overflow-auto w-64 border-r border-r-border px-3 py-6">
      <Link to="/">
        <div className="items-center py-2 px-4 mt-1 leading-5 font-semibold text-sm flex gap-2">
          <Home />
          Home
        </div>
      </Link>
      <Link to={`/projects/${props.projectId}/`}>
        <div
          className={cn(
            "items-center py-2 px-4 mt-1 leading-5 font-semibold text-sm flex gap-2",
            !linkLocation && "bg-secondary rounded",
          )}
        >
          <LayoutDashboard />
          Project Dashboard
        </div>
      </Link>
      {apps.map((section) => {
        return (
          <div key={section.name} className="mt-4">
            <p className="scroll-m-20 text-xl font-extrabold tracking-tight lg:text-2xl py-2 px-4">
              {section.name}
            </p>
            {section.items.map((app) => {
              const Icon = app.icon;
              return (
                <Fragment key={app.name}>
                  <Link to={`/projects/${props.projectId}/${app.path}/`}>
                    <div
                      className={cn(
                        "items-center py-2 px-4 mt-1 leading-5 font-semibold text-sm flex gap-2",
                        linkLocation === app.path && "bg-secondary rounded",
                      )}
                    >
                      <Icon />
                      {app.name}
                    </div>
                  </Link>
                  <div className="ml-7 mt-1 mb-4 border-l flex flex-col justify-between gap-2">
                    {app.subsections?.map((sub) => {
                      return (
                        <Link
                          className="translate-y-[9px]"
                          key={sub.name}
                          to={`/projects/${props.projectId}/${app.path}/${sub.path}/`}
                        >
                          <div
                            className={cn(
                              "leading-4 text-sm flex gap-3 items-center",
                              linkLocation === sub.path &&
                                "bg-secondary rounded",
                            )}
                          >
                            <hr className="w-3" />
                            {sub.name}
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </Fragment>
              );
            })}
          </div>
        );
      })}
    </nav>
  );
}
