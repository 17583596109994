import { AuthContext } from "dashboard-common";
import {
  ListProjectsSuccessResponse,
  OrganizationRole,
  OrganizationRoleAdmin,
} from "identity-api";
import { Building2 } from "lucide-react";
import * as React from "react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  BasicList,
  BreadCrumbs,
  DebouncedSearch,
  DocsLink,
  EmptyState,
  ErrorAlert,
  H2,
  ItemPagination,
  ListElement,
  Sonner,
  Title,
  useDataFetcherState,
  useDocumentTitle,
} from "ui-components";
import { ListElementSkeleton } from "ui-components/src";
import { Link, useLocation, useSearch } from "wouter";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";
import CreateProjectModal from "../../ProjectsDashboard/CreateProjectModal";

type ProjectsListViewProps = {
  organizationId: string;
  organizationDisplayName: string;
  organizationRole?: OrganizationRole;
};

export function ProjectsListView({
  organizationId,
  organizationDisplayName,
  organizationRole,
}: ProjectsListViewProps) {
  const [, setLocation] = useLocation();
  const search = useSearch();
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const dataFetcher =
    useDataFetcherState<ListProjectsSuccessResponse["body"]>();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const searchQueryRef = useRef<HTMLInputElement>(null);

  const firstTime = search ? search.includes("first-time=true") : false;

  const breadCrumbPath = [
    {
      name: (
        <div className="flex gap-2 items-center">
          <Building2 />
          {organizationDisplayName as string}
        </div>
      ),
      to: `/organizations/${organizationId}/`,
    },
    {
      name: "Projects",
    },
  ];

  const fetchProjects = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listProjects({
        parameters: {
          organizationId,
          offset: (currentPage - 1) * itemsPerPage,
          search: searchQueryRef.current?.value || "",
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [
    getAccessToken,
    onUnauthorized,
    currentPage,
    organizationId,
    dataFetcher,
  ]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects, currentPage, itemsPerPage]);

  useDocumentTitle(`Projects - ${organizationDisplayName}`);

  return (
    <>
      <BreadCrumbs path={breadCrumbPath} LinkElement={Link} />
      <div className="max-w-none relative mb-5">
        <Title>
          <div className="flex gap-2 items-baseline">
            <H2>Projects</H2>
            <DocsLink path="/organizations/projects" text="Docs" />
          </div>
          <div className="w-auto h-auto relative">
            {!dataFetcher.isLoading &&
              !dataFetcher.data?.projects.length &&
              firstTime && (
                <div className="h-full w-full bg-blue-600 dark:bg-blue-500 rounded-lg absolute animate-ping" />
              )}
            <div className="relative">
              <CreateProjectModal
                withinOrganizationId={organizationId}
                onProjectCreated={(project) => {
                  setLocation(`/projects/${project.id}/`);
                  Sonner.toast("Project created");
                }}
                disabled={organizationRole !== OrganizationRoleAdmin}
              />
            </div>
          </div>
        </Title>
        <div className="w-full min-h-full mx-auto pb-20">
          <ItemPagination
            loading={dataFetcher.isLoading}
            className="pt-20"
            totalItems={dataFetcher.data?.totalResults || null} // total number of available items
            itemsPerPage={itemsPerPage}
            currentPage={currentPage} // initial page
            setPage={setCurrentPage} // callback to set the current page
          >
            <DebouncedSearch
              ref={searchQueryRef}
              onSearchChange={fetchProjects}
              placeholder="Filter projects by name or id prefix (case-sensitive)..."
              className="w-1/3 min-w-[380px] mb-4"
            />
            <BasicList>
              {dataFetcher.error && (
                <ErrorAlert
                  message={`There was a problem fetching the projects: ${dataFetcher.error.message}`}
                  action={{
                    text: "Retry",
                    cb: () => {
                      fetchProjects();
                    },
                  }}
                />
              )}
              {dataFetcher.isLoading &&
                Array.from(Array(12).keys()).map((i) => (
                  <ListElementSkeleton key={i} />
                ))}
              {dataFetcher.data &&
                dataFetcher.data.projects.map((project) => (
                  <ListElement
                    key={project.id}
                    to={`/projects/${project.id}/`}
                    LinkElement={Link}
                  >
                    <div className="flex-col flex text-sm basis-2/5">
                      Name:
                      <span className="text-xl font-semibold">
                        {project.name}
                      </span>
                    </div>
                    <div className="flex-col flex text-sm">
                      ID:
                      <span className="text-xl font-semibold">
                        {project.id}
                      </span>
                    </div>
                    {project.description && (
                      <div className="flex-col flex text-sm">
                        Description
                        <span className="text-xl font-semibold">
                          {project.description}
                        </span>
                      </div>
                    )}
                  </ListElement>
                ))}
              {!dataFetcher.isLoading &&
                !dataFetcher.data?.projects.length &&
                !firstTime && <EmptyState message="There are no projects." />}
              {firstTime &&
                !dataFetcher.isLoading &&
                !dataFetcher.data?.projects.length && (
                  <EmptyState message="It looks like you don't have any projects yet. Create one to get started." />
                )}
            </BasicList>
          </ItemPagination>
        </div>
      </div>
    </>
  );
}
