import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "dashboard-common";
import { MemberPermission, PermissionMode } from "identity-api";
import * as React from "react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "ui-components";
import * as z from "zod";

import { createIdentityServiceClient } from "../../../../IdentityServiceClient";
import {
  permissionResources,
  permissionResourceToDisplayName,
  permissionResourceType,
} from "../../permissionResources";
import SelectProjectInput from "../../SelectProjectInput/SelectProjectInput";

type CreateMemberPermissionModalProps = {
  memberId: string;
  organizationId: string;
  withinOrganizationId?: string;
  onMemberPermissionCreated: (project: MemberPermission) => void;
  disabled: boolean;
};

const createMemberPermission = (
  memberId: string,
  organizationId: string,
  projectId: string,
  resource: permissionResourceType,
  verb: PermissionMode,
  getAccessToken: () => Promise<string>,
  onUnauthorized: () => void,
) =>
  createIdentityServiceClient(getAccessToken, onUnauthorized)
    .createMemberPermission({
      parameters: {
        organizationId,
        memberId,
      },
      body: {
        projectId,
        permission: {
          resource,
          verb,
        },
      },
    })
    .then((response) => {
      if (response.code === 200) {
        return response.body;
      } else {
        console.error("Failed to create member permission");
        throw new Error(response.body.message);
      }
    });

export const formSchema = z.object({
  projectId: z.string().min(1, { message: "Project id is required" }),
  verb: z.string().min(1, { message: "Verb is required" }),
  resource: z.enum(permissionResources),
});

function CreateMemberPermissionModal({
  memberId,
  organizationId,
  onMemberPermissionCreated,
  disabled,
}: CreateMemberPermissionModalProps) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    disabled: isSubmitting,
    resolver: zodResolver(formSchema),
    mode: "onChange",
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    if (error) {
      setError(null);
    }
    setIsSubmitting(true);

    try {
      const project = await createMemberPermission(
        memberId,
        organizationId,
        data.projectId,
        data.resource,
        data.verb as PermissionMode, //TODO - use selection rather than string input

        getAccessToken,
        onUnauthorized,
      );

      onMemberPermissionCreated(project);
      setOpen(false);
    } catch (e) {
      setError(e as Error);
      return;
    } finally {
      setIsSubmitting(false);
    }
  }

  const Trigger = disabled ? TooltipTrigger : DialogTrigger;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Tooltip>
        <Trigger asChild>
          <Button
            className="rounded-lg bg-foreground !pointer-events-auto"
            disabled={disabled}
          >
            Create Member Permission
          </Button>
        </Trigger>
        {disabled && (
          <TooltipContent>
            <p>This action can only be performed by an Admin</p>
          </TooltipContent>
        )}
      </Tooltip>
      <DialogContent className={error ? "border-destructive" : ""}>
        <DialogHeader>
          <DialogTitle className={error ? "text-destructive" : ""}>
            Create Member Permission
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="projectId"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <Label className="block mb-2" htmlFor="project-id">
                    Project Id
                  </Label>
                  <SelectProjectInput
                    value={field.value}
                    onChange={(proId) => field.onChange(proId)}
                    getAccessToken={getAccessToken}
                    onUnauthorized={onUnauthorized}
                    organizationId={organizationId}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="resource"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <Label className="block mb-2" htmlFor="resource">
                    Resource
                  </Label>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a resource type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {permissionResources.map((resource) => (
                        <SelectItem key={resource} value={resource}>
                          {permissionResourceToDisplayName(resource)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="verb"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <Label className="block mb-2" htmlFor="verb">
                    Verb
                  </Label>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a verb" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="read">Read</SelectItem>
                      <SelectItem value="write">Write</SelectItem>
                      <SelectItem value="admin">Admin</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="mt-4">
              {error && (
                <p className="flex-1 text-destructive self-center">
                  <div>There was a problem creating the project:</div>
                  <div>{error.message}</div>
                </p>
              )}
              <Button
                disabled={!form.formState.isValid || isSubmitting}
                variant={error ? "destructive" : "default"}
              >
                {isSubmitting ? "Creating..." : error ? "Retry" : "Create"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateMemberPermissionModal;
