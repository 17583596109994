import * as React from "react";
import { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  FormItem,
  Input,
  Label,
} from "ui-components";
import { DialogFooter } from "ui-components/src";

type APIKeyCreatedModalProps = {
  apiKeyToken: string;
  onClose: () => void;
  onNavigateTo: () => void;
};

function APIKeyCreatedModal({
  apiKeyToken,
  onClose,
  onNavigateTo,
}: APIKeyCreatedModalProps) {
  const [showToken, setShowToken] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>API Key</DialogTitle>
        </DialogHeader>
        <FormItem className="w-full mt-2">
          <div>Copy this token. You will not be able to retrieve it again.</div>
          <Label className="block mb-2" htmlFor="project-name">
            API Key Token
          </Label>
          {showToken && (
            <Input type={"text"} value={apiKeyToken} disabled={true} />
          )}
          {!showToken && (
            <Input type={"password"} value={apiKeyToken} disabled={true} />
          )}
          <Button
            className="mt-2"
            variant={"default"}
            onClick={() => setShowToken(!showToken)}
          >
            {showToken ? "Hide" : "Show"}
          </Button>
          <Button
            className="mt-2 ml-2"
            variant={"default"}
            onClick={() => {
              navigator.clipboard.writeText(apiKeyToken);
              setCopied(true);
            }}
          >
            {copied ? "Copied..." : "Copy"}
          </Button>
        </FormItem>
        <DialogFooter className="mt-4">
          <Button variant={"default"} onClick={onNavigateTo}>
            Go to API Key Configuration
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default APIKeyCreatedModal;
