import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "dashboard-common";
import { OrganizationInvite } from "identity-api";
import * as React from "react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "ui-components";
import { DialogFooter } from "ui-components/src";
import * as z from "zod";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";

type CreateInviteModalProps = {
  organizationId: string;
  onInviteCreated: (invite: OrganizationInvite, inviteToken: string) => void;
  disabled: boolean;
};

const createInvite = (
  email: string,
  role: "admin" | "member",
  organizationId: string,
  getAccessToken: () => Promise<string>,
  onUnauthorized: () => void,
) =>
  createIdentityServiceClient(getAccessToken, onUnauthorized)
    .createInvite({
      parameters: {
        organizationId,
      },
      body: {
        email,
        role,
      },
    })
    .then((response) => {
      if (response.code === 200) {
        return response.body;
      } else {
        console.error("Failed to create invite");
        throw new Error(response.body.message);
      }
    });

export const formSchema = z.object({
  email: z.string().min(1, { message: "Email is required" }).trim(),
  role: z.string().min(1, { message: "Role is required" }),
});

function CreateInviteModal({
  onInviteCreated,
  organizationId,
  disabled,
}: CreateInviteModalProps) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const Trigger = disabled ? TooltipTrigger : DialogTrigger;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Tooltip>
          <Trigger asChild>
            <Button
              className="rounded-lg bg-foreground !pointer-events-auto"
              disabled={disabled}
            >
              Create Invite
            </Button>
          </Trigger>
          {disabled && (
            <TooltipContent>
              <p>This action can only be performed by an Admin</p>
            </TooltipContent>
          )}
        </Tooltip>
      </DialogTrigger>
      <DialogContent className={error ? "border-destructive" : ""}>
        <DialogHeader>
          <DialogTitle className={error ? "text-destructive" : ""}>
            Create Invite
          </DialogTitle>
        </DialogHeader>
        {open && (
          <CreateInviteModalForm
            organizationId={organizationId}
            onInviteCreated={onInviteCreated}
            setOpen={setOpen}
            error={error}
            setError={setError}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

function CreateInviteModalForm({
  organizationId,
  onInviteCreated,
  setOpen,
  error,
  setError,
}: Omit<CreateInviteModalProps, "disabled"> & {
  error: Error | null;
  setError: (error: Error | null) => void;
  setOpen: (open: boolean) => void;
}) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    disabled: isSubmitting,
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      role: "",
    },
    mode: "onChange",
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    if (error) {
      setError(null);
    }
    setIsSubmitting(true);

    try {
      const createInviteResponse = await createInvite(
        data.email,
        // TODO - fix this type
        data.role as "admin" | "member",
        organizationId,
        getAccessToken,
        onUnauthorized,
      );

      onInviteCreated(
        createInviteResponse.invite,
        createInviteResponse.inviteToken,
      );
      setOpen(false);
    } catch (e) {
      setError(e as Error);
      return;
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="mb-4">
              <Label className="block mb-2" htmlFor="email">
                Email Address
              </Label>
              <Input id="email" type="text" placeholder="Email" {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem className="my-4">
              <Label className="block mb-2" htmlFor="role">
                Role
              </Label>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a role" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="member">Member</SelectItem>
                  <SelectItem value="admin">Admin</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="mt-4">
          {error && (
            <p className="flex-1 text-destructive self-center">
              <div>There was a problem creating the invite:</div>
              <div>{error.message}</div>
            </p>
          )}
          <Button
            disabled={!form.formState.isValid || isSubmitting}
            variant={error ? "destructive" : "default"}
          >
            {isSubmitting ? "Creating..." : error ? "Retry" : "Create"}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}

export default CreateInviteModal;
