import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "dashboard-common";
import { TeamMember } from "identity-api";
import * as React from "react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "ui-components";
import * as z from "zod";

import { createIdentityServiceClient } from "../../../../IdentityServiceClient";
import SelectMemberInput from "../../SelectMemberInput/SelectMemberInput";

type CreateTeamMemberModalProps = {
  teamId: string;
  organizationId: string;
  withinOrganizationId?: string;
  onTeamMemberCreated: (member: TeamMember) => void;
  disabled: boolean;
};

const createTeamMember = (
  teamId: string,
  organizationId: string,
  userId: string,
  role: "admin" | "member",
  getAccessToken: () => Promise<string>,
  onUnauthorized: () => void,
) =>
  createIdentityServiceClient(getAccessToken, onUnauthorized)
    .createTeamMember({
      parameters: {
        organizationId,
        teamId,
      },
      body: {
        userId,
        role,
      },
    })
    .then((response) => {
      if (response.code === 200) {
        return response.body;
      } else {
        console.error("Failed to create member");
        throw new Error(response.body.message);
      }
    });

export const formSchema = z.object({
  userId: z.string().min(1, { message: "User Id is required" }),
  role: z.string().min(1, { message: "Role is required" }),
});

function CreateTeamMemberModal({
  teamId,
  organizationId,
  onTeamMemberCreated,
  disabled,
}: CreateTeamMemberModalProps) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const Trigger = disabled ? TooltipTrigger : DialogTrigger;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Tooltip>
        <Trigger asChild>
          <Button
            className="rounded-lg bg-foreground !pointer-events-auto"
            disabled={disabled}
          >
            Create Team Member
          </Button>
        </Trigger>
        {disabled && (
          <TooltipContent>
            <p>This action can only be performed by an Admin</p>
          </TooltipContent>
        )}
      </Tooltip>
      <DialogContent className={error ? "border-destructive" : ""}>
        <DialogHeader>
          <DialogTitle className={error ? "text-destructive" : ""}>
            Add Team Member
          </DialogTitle>
          {open && (
            <CreateTeamMemberModalForm
              teamId={teamId}
              organizationId={organizationId}
              onTeamMemberCreated={onTeamMemberCreated}
              error={error}
              setError={setError}
              setOpen={setOpen}
            />
          )}
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

function CreateTeamMemberModalForm({
  teamId,
  organizationId,
  onTeamMemberCreated,
  error,
  setError,
  setOpen,
}: Omit<CreateTeamMemberModalProps, "disabled"> & {
  error: Error | null;
  setError: (error: Error | null) => void;
  setOpen: (open: boolean) => void;
}) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    disabled: isSubmitting,
    resolver: zodResolver(formSchema),
    mode: "onChange",
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    if (error) {
      setError(null);
    }
    setIsSubmitting(true);

    try {
      const member = await createTeamMember(
        teamId,
        organizationId,
        data.userId,
        // TODO - use dropdown to avoid casting here
        data.role as "admin" | "member",
        getAccessToken,
        onUnauthorized,
      );

      onTeamMemberCreated(member);
      setOpen(false);
    } catch (e) {
      setError(e as Error);
      return;
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="userId"
          render={({ field }) => (
            <FormItem>
              <Label className="block mb-2" htmlFor="member-id">
                Member
              </Label>
              <SelectMemberInput
                value={field.value}
                onChange={(memberId) => field.onChange(memberId)}
                getAccessToken={getAccessToken}
                onUnauthorized={onUnauthorized}
                organizationId={organizationId}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem className="my-4">
              <Label className="block mb-2" htmlFor="role">
                Role
              </Label>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a role" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="member">Member</SelectItem>
                  <SelectItem value="admin">Admin</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="mt-4">
          {error && (
            <p className="flex-1 text-destructive self-center">
              <div>There was a problem creating the member:</div>
              <div>{error.message}</div>
            </p>
          )}
          <Button
            disabled={!form.formState.isValid || isSubmitting}
            variant={error ? "destructive" : "default"}
          >
            {isSubmitting ? "Creating..." : error ? "Retry" : "Create"}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}

export default CreateTeamMemberModal;
