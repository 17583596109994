import { SessionProvider } from "next-auth/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";

import "./index.css";
import "./tailwind.css";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <SessionProvider>
    <App />
  </SessionProvider>,
);
