import { AuthContext } from "dashboard-common";
import { ListOrganizationsSuccessResponse } from "identity-api";
import * as React from "react";
import { useCallback, useContext, useEffect } from "react";
import {
  H2,
  MenuBar,
  MenuItem,
  Skeleton,
  Sonner,
  useDataFetcherState,
  useDocumentTitle,
} from "ui-components";
import { Link, Route, useLocation } from "wouter";

import { createIdentityServiceClient } from "../../IdentityServiceClient";
import OrganizationList from "../OrganizationsList/OrganizationList";
import CreateOrganizationModal from "../ProfileView/CreateOrganizationModal";
import { ProjectsList } from "../ProjectsDashboard/ProjectsList";

export function Home() {
  const { user } = useContext(AuthContext);
  const [location, setLocation] = useLocation();
  const splitLocation = location.split("/").filter((pathItem) => !!pathItem);
  const currentLocation = splitLocation[splitLocation.length - 1];

  useDocumentTitle(`Dashboard`);

  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const dataFetcher =
    useDataFetcherState<ListOrganizationsSuccessResponse["body"]>();

  const fetchOrganizations = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listOrganizations({
        parameters: {
          offset: 0,
          limit: 10,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError({
            message: response.body.message,
            code: response.code,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [getAccessToken, onUnauthorized, dataFetcher]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  return (
    <div className="max-w-[1280px] w-full mx-auto relative pt-8 px-12 h-full mt-10">
      <div className="h-full">
        <div className="h-40 mb-12 max-w-none rounded-lg relative">
          <H2 className="font-bold mb-8">Welcome, {user?.displayName}</H2>
          <img
            alt="Profile Picture"
            src={user?.photoURL ?? "/profile-picture.png"}
            className="absolute right-0 top-0 bottom-0 w-40 m-0 rounded-lg aspect-square object-cover"
          />
        </div>
        {dataFetcher.isLoading && (
          <div className="mb-20">
            <Skeleton className="h-20 mb-5"></Skeleton>
            <Skeleton className="h-20 mb-5"></Skeleton>
            <Skeleton className="h-20 mb-5"></Skeleton>
            <Skeleton className="h-20 mb-5"></Skeleton>
            <Skeleton className="h-20 mb-5"></Skeleton>
          </div>
        )}
        {(dataFetcher.data?.totalResults ?? 0) > 0 && (
          <>
            <MenuBar className="mb-10">
              <MenuItem
                active={currentLocation !== "organizations"}
                to={`/`}
                LinkElement={Link}
              >
                Projects
              </MenuItem>
              <MenuItem
                active={currentLocation === "organizations"}
                to={`/organizations`}
                LinkElement={Link}
              >
                Organizations
              </MenuItem>
            </MenuBar>
            <Route path="/organizations">
              <OrganizationList />
            </Route>
            <Route path="/">
              <ProjectsList />
            </Route>
          </>
        )}

        {!dataFetcher.isLoading && !dataFetcher.data?.totalResults && (
          <div className="mb-20 mt-40">
            <p className="text-[2rem] font-semibold text-center whitespace-pre">
              {
                "It looks like you don't belong to any organizations.\nPlease create one to get started."
              }
            </p>
            <div className="flex justify-center items-center mt-10">
              <div className="w-auto h-auto relative">
                <div className="h-full w-full bg-blue-600 dark:bg-blue-500 rounded-lg absolute animate-ping" />
                <div className="relative">
                  <CreateOrganizationModal
                    onOrganizationCreated={(organization) => {
                      Sonner.toast("Organization created");
                      setLocation(
                        `/organizations/${organization.id}/projects?first-time=true`,
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
