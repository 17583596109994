import {
  Organization,
  OrganizationRole,
  OrganizationRoleAdmin,
} from "identity-api";
import { Building2 } from "lucide-react";
import * as React from "react";
import {
  BreadCrumbs,
  DocsLink,
  ErrorAlert,
  ErrorContents,
  H2,
  Sonner,
  useDocumentTitle,
} from "ui-components";
import { Link } from "wouter";

import EditOrganizationModal from "./EditOrganizationModal";

type OrganizationInfoProps = {
  organization: Organization | null;
  organizationRole: OrganizationRole;
  loading: boolean;
  error: ErrorContents | null;
  updateOrganization: (organization: Organization) => void;
  onRetry: () => void;
};

export function OrganizationInfo({
  organization,
  organizationRole,
  loading,
  updateOrganization,
  error,
  onRetry,
}: OrganizationInfoProps) {
  useDocumentTitle(
    `Organization - ${loading ? "Loading..." : organization?.name || "Error"}`,
  );
  const breadCrumbPath = [
    {
      name: (
        <div className="flex gap-2 items-center">
          <Building2 />
          {organization?.name as string}
        </div>
      ),
    },
  ];

  return (
    <>
      <BreadCrumbs path={breadCrumbPath} LinkElement={Link} />
      {loading ? (
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-2 items-baseline">
            <H2 className="font-bold m-0">Organization Info</H2>
            <DocsLink path="/organizations/organization" text="Docs" />
          </div>
        </div>
      ) : (
        <div className="relative">
          {organization && (
            <>
              <EditOrganizationModal
                disabled={organizationRole !== OrganizationRoleAdmin}
                organization={organization}
                updateOrganization={(updatedOrganization) => {
                  Sonner.toast("Organization updated");
                  updateOrganization(updatedOrganization);
                }}
              />
              <div className="flex justify-between items-center mb-4">
                <div className="flex gap-2 items-baseline">
                  <H2 className="font-bold m-0">Organization Info</H2>
                  <DocsLink path="/organizations/organization" text="Docs" />
                </div>
              </div>
              <dl className="flex items-end m-0 *:m-0 *:p-0 gap-2">
                <dt>Organization Id:</dt>
                <dd>{organization?.id}</dd>
              </dl>
            </>
          )}
        </div>
      )}
      {error && (
        <div className="w-full relative mt-8">
          <ErrorAlert
            message={error.message}
            action={{
              text: "Retry",
              cb: () => onRetry(),
            }}
          />
        </div>
      )}
    </>
  );
}
