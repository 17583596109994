import { getCsrfToken, signOut, useSession } from "next-auth/react";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { H1, Skeleton } from "ui-components";
import { Separator } from "ui-components/src";

import { Dashboard } from "./Dashboard";
import { MutatableThemeProvider } from "./state/MutatableThemeContext";

type SessionData = {
  user: {
    name: string;
    email: string;
    image: string;
    id: string;
  };
  sessionToken: string;
};

function App() {
  const { data: authSession, status: userStatus } = useSession();
  const isLoading = userStatus === "loading";

  const sessionData = authSession as SessionData | null;
  const user = sessionData?.user;

  const [csrfToken, setCsrfToken] = useState<string | null>(null);
  useEffect(() => {
    if (!isLoading) {
      getCsrfToken().then((csrfToken) => {
        setCsrfToken(csrfToken!);
      });
    }
  }, [isLoading]);

  // The above, but with the correct state management

  const onUnauthorized = useCallback(() => {
    signOut({ redirect: true });
  }, []);

  if (isLoading) {
    return (
      <MutatableThemeProvider defaultTheme="dark" storageKey="ui-theme">
        <Skeleton className="w-full h-navbar-height rounded-none" />
        <Skeleton className="w-full h-body-height rounded-none" />
      </MutatableThemeProvider>
    );
  }

  if (!sessionData) {
    return (
      <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 border p-8 rounded-xl w-[400px]">
        <div className="flex items-center justify-center w-full">
          <H1 className="-ml-2">MServe</H1>
        </div>
        <p className="text-2xl text-center mb-5">Sign in to continue</p>
        <Separator />
        <div className="flex flex-col gap-2 mt-5">
          {csrfToken && (
            <form
              className={"flex items-center justify-center w-full"}
              action="/api/auth/signin/google"
              method="POST"
            >
              <input type="hidden" name="csrfToken" value={csrfToken} />
              <input
                type="hidden"
                name="callbackUrl"
                value={window.location.href}
              />
              <button
                onClick={() => {}}
                className="justify-center px-4 py-2 border flex gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150"
              >
                <img
                  className="w-6 h-6"
                  src="/google-icon.svg"
                  loading="lazy"
                  alt="google logo"
                />
                <span>Login with Google</span>
              </button>
            </form>
          )}
        </div>
      </div>
    );
  }

  return (
    <Dashboard
      user={user!}
      onUnauthorized={onUnauthorized}
      sessionToken={sessionData.sessionToken}
    />
  );
}

export default App;
