import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "dashboard-common";
import { MessageSquare } from "lucide-react";
import * as React from "react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormField,
  FormItem,
  FormMessage,
  Label,
  Separator,
  Textarea,
} from "ui-components";
import { Button } from "ui-components/src";
import * as z from "zod";

import { createDashboardServiceClient } from "../../DashboardServiceClient";

const sendFeedback = (
  message: string,
  canContact: boolean,
  getAccessToken: () => Promise<string>,
  onUnauthorized: () => void,
) =>
  createDashboardServiceClient(getAccessToken, onUnauthorized)
    .sendFeedback({
      parameters: {},
      body: {
        message,
        canContact,
        url: window.location.href,
      },
    })
    .then((response) => {
      if (response.code === 200) {
        return response.body;
      } else {
        console.error("Failed to send feedback");
        throw new Error(response.body.message);
      }
    });

export const formSchema = z.object({
  message: z.string().min(1, { message: "Message is required" }),
  canContact: z.boolean(),
});

function SendFeedback() {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  return (
    <div className="mr-4">
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button variant="outline" size="icon">
            <MessageSquare />
          </Button>
        </DialogTrigger>
        <DialogContent className={error ? "border-destructive" : ""}>
          <DialogHeader>
            <DialogTitle className={error ? "text-destructive" : ""}>
              Bug Report & Feedback
            </DialogTitle>
          </DialogHeader>
          <SendFeedbackModal
            error={error}
            setError={setError}
            setOpen={setOpen}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

type SendFeedbackModalProps = {
  error: Error | null;
  setError: (error: Error | null) => void;
  setOpen: (open: boolean) => void;
};

const FEEDBACK_TEXT = `We value your feedback as it helps us improve. If you come across any
issues or have suggestions for enhancing MServe, please share them
below. Let's work together to make your experience even better.`;

function SendFeedbackModal({
  error,
  setError,
}: SendFeedbackModalProps & {
  error: Error | null;
  setError: (error: Error | null) => void;
}) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    disabled: isSubmitting,
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: "",
      canContact: false,
    },
    mode: "onChange",
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    if (error) {
      setError(null);
    }
    setIsSubmitting(true);

    try {
      console.log(data);

      await sendFeedback(
        data.message,
        data.canContact,
        getAccessToken,
        onUnauthorized,
      );

      setSuccess(true);
    } catch (e) {
      setError(e as Error);
      return;
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <p className="text-sm">{FEEDBACK_TEXT}</p>
        <Separator className="my-4" />
        <FormField
          control={form.control}
          name="message"
          render={({ field }) => (
            <FormItem className="mb-4">
              <Textarea
                placeholder="Please enter your feedback here..."
                {...field}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          name="canContact"
          render={({ field }) => (
            <FormItem className="flex items-center gap-2 mb-2">
              <Checkbox
                checked={field.value}
                onCheckedChange={field.onChange}
              />
              <Label className="mt-2" htmlFor="canContact">
                {`Check this box if you're okay with us contacting you about your
feedback.`}
              </Label>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="mt-4">
          {error && (
            <p className="flex-1 text-destructive self-center">
              <div>There was a problem sending the feedback:</div>
              <div>{error.message}</div>
            </p>
          )}
          {success && (
            <p className="flex-1 text-green-500 self-center">
              Feedback sent successfully!
            </p>
          )}
          <Button
            disabled={!form.formState.isValid || isSubmitting}
            variant={error ? "destructive" : "default"}
          >
            {isSubmitting ? "Sending..." : error ? "Retry" : "Send"}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}

export default SendFeedback;
