import { identityServiceSchema } from "identity-api";

export const permissionResources =
  identityServiceSchema.components.schemas.Permission.properties.resource.enum;

export type permissionResourceType = (typeof permissionResources)[number];

export function permissionResourceToDisplayName(
  resource: permissionResourceType,
) {
  switch (resource) {
    case "worlds":
      return "Worlds";
    case "mml-objects":
      return "MML Objects";
    default:
      return resource;
  }
}
