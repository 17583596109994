import * as React from "react";
import { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  FormItem,
  Input,
  Label,
} from "ui-components";

type InviteCreatedModalProps = {
  inviteUrl: string;
  onClose: () => void;
};

function InviteCreatedModal({ inviteUrl, onClose }: InviteCreatedModalProps) {
  const [showToken, setShowToken] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Invite</DialogTitle>
        </DialogHeader>
        <FormItem className="w-full mt-2">
          <p>
            Copy this url and provide it to the recipient. You will not be able
            to retrieve it again.
          </p>
          <p>
            The recipient will need to use an account with the verified email to
            accept the invite.
          </p>
          <Label className="block mb-2" htmlFor="project-name">
            Invite URL
          </Label>
          {showToken && (
            <Input type={"text"} value={inviteUrl} disabled={true} />
          )}
          {!showToken && (
            <Input type={"password"} value={inviteUrl} disabled={true} />
          )}
          <Button
            className="mt-2"
            variant={"default"}
            onClick={() => setShowToken(!showToken)}
          >
            {showToken ? "Hide" : "Show"}
          </Button>
          <Button
            className="mt-2 ml-2"
            variant={"default"}
            onClick={() => {
              navigator.clipboard.writeText(inviteUrl);
              setCopied(true);
            }}
          >
            {copied ? "Copied..." : "Copy"}
          </Button>
        </FormItem>
      </DialogContent>
    </Dialog>
  );
}

export default InviteCreatedModal;
